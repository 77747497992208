import React from 'react';
import PropTypes from 'prop-types';

import Legal from '../legal';

export const PrivacyPolicy = ({
    location,
    data: {
        contentful: {
            privacyPolicyPageCollection: {
                items: [page],
            },
        },
    },
}) => {
    const { title, content, seo } = page;

    const seoData = {
        title: `${seo.title} - Agostinho & João Pinto, Lda`,
        description: seo.description,
        image: seo.image?.url,
    };

    return (
        <Legal
            title={ title }
            seoData={ seoData }
            location={ location }
            content={ content.json } />
    );
};

PrivacyPolicy.propTypes = {
    data: PropTypes.shape({
        contentful: PropTypes.shape({
            privacyPolicyPageCollection: PropTypes.shape({
                items: PropTypes.array.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default PrivacyPolicy;
